<template>
    <div class="addenterprise">
      <div class="essential_information">
       <div class="flex_top">
        <div class="store_table">
          <div class="store_table_item active">
            <span>企业资料</span>
          </div>
        </div>
        <div class="form_essential">
          
         <div >
            <span>企业logo</span>
            <img class="left_distance" width="100" height="30" :src="form.companyLogo" alt="">
         </div>
         <div>
            <span>企业名称</span>
            <span class="left_distance">{{form.companyFullName}}</span>
         </div>
         <div>
            <span>所在地区</span>
            <span class="left_distance">{{form.province}}-{{form.city }}
            <span v-if="form.district">-{{ form.district }}</span>
            </span>
         </div>
         <div>
            <span>企业规模</span>
            <span class="left_distance">{{ form.enterpriseScale }}</span>
         </div>
         <div style="display: flex;align-items: flex-start;">
            <span>企业简介</span>
            <span class="enterpriseIntro">
                {{ form.enterpriseIntro }}
            </span>
         </div>
        </div>
    </div>
        <div class="store_table_bottom">
            <div class="store_table">
          <div class="store_table_item active">
            <span>联络人信息</span>
          </div>
        </div>
            <div class="store_table_bottom_div" v-for="(item,index) in form.liaisonsCompanyResultList" :key="index">
                <div>
                    <div class="width_left">
                        <span>联络人</span>
                    </div>
                    <div class="left_distance">{{ item.liaisonsName }}</div>
                </div>
                <div>
                    <div class="width_left">
                        <span>联络方式</span>
                    </div>
                    <div class="left_distance">{{ item.liaisonsContactWay }}</div>
                </div>
                <div>
                    <div class="width_left">
                        <span>联络人职位</span>
                    </div>
                    <div class="left_distance">{{ item.liaisonsPosition }}</div>
                </div>
            </div>
            <div style="margin-top: 30px" class="footer_bottom">
          <el-button @click="clcans">返回上一级</el-button>
        </div>
        </div>
       
        
        
      </div>
    </div>
  </template>
  
  <script>

  import { getCompanyDetail } from '@/api/datement'

  
  export default {
    name: 'cpmpanyinfo',
    computed: {
      id() {
        return this.$route.query.id
      }
    },
    data() {
      return {
        form: {
         
        }, //表单收集
      }
    },
    created() {
        this.getcompany()
    },
    methods: {
      
      //查询企业信息回显
      async getcompany() {
        const query = { id: this.id }
        const res = await getCompanyDetail(query)
        if (res.data.resultCode == 200) {
          this.form = res.data.data
        
  
        }
      },
  
  
      clcans() {
        this.$router.go(-1)
      }
    }
  }
  </script>
  <style lang="less" scoped>

  .addenterprise {
    .left_distance{
        margin-left: 20px;
    }
    .flex_top{
        background: #fff;
        margin-top: 20px;
        padding: 10px 35px;
    }
    .form_essential div{
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        .enterpriseIntro{
            width: 560px;
            margin-left: 20px;
        }
    }
    .store_table_bottom{
        background: #fff;
        margin-top: 20px;
        padding: 10px 35px;
        .store_table_bottom_div div{
            margin-bottom: 15px;
        display: flex;
        align-items: center;
        .width_left{
            width: 80px;
           display: flex;
           justify-content: right;
        }
        }
    }
    
    .store_table {
      box-sizing: border-box;
      margin: 0 72px 20px 0;
      display: flex;
      flex-direction: column;
      
      .store_table_item {
        padding-top: 20px;
        color: #999999;
        margin-right: 50px;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 550;
      }
  
      .active {
        color: #4e93fb;
        font-size: 20px;
        border-radius: 1px;
      }
    }
  
   
    .footer_bottom {
      border-top: 1px solid #f5f5f5;
      padding-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
  </style>
  